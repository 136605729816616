<template>
  <div>
     <b-card>
    <b-tabs>
      <b-tab active title="English">
        <b-card-text>
          <validation-observer ref="themeForm">
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Theme Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="theme.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Theme Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-tab>
      <b-tab title="Hindi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="theme.translations.hi.name"
                    placeholder="Theme Name (Hindi)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
      <b-tab title="Marathi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="theme.translations.mr.name"
                    placeholder="Theme Name (Marathi)"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
  <b-card>
      <b-row>
        <b-col>
          <b-button @click="createTheme" size="lg" block variant="primary">Submit</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BImg,
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BImg,
  },
  data() {
    return {
      required,
      theme: {
        name: "",
        translations: {
          hi: {
            name: "",
          },
          mr: {
            name: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createTheme() {
      this.$refs.themeForm.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/themes/create`,
              this.theme,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.name} has been created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while creating theme!"
              );
            });
        }
      });
    },
  },
};
</script>
 